<template>
  <b-overlay :show="loading">
  <section>
    <header class="mb-2">
      <b-card>
        <b-row>
          <b-col md="2" sm="12">
            <p><strong>No</strong></p>
            <p>{{ pb.no }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Tanggal</strong></p>
            <p>{{ humanDate(pb.tanggal) }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Kendaraan</strong></p>
            <p>{{ pb.kendaraan?.nama_kendaraan }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Driver</strong></p>
            <p>{{ pb.driver?.nama_lengkap }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Total</strong></p>
            <p>Rp. {{ pb.total ? formatRupiah(pb.total) : "-" }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ pb.keterangan ? pb.keterangan : "-" }}</p>
          </b-col>
          <!-- Status Info -->
          <!-- / -->
          <b-col
            md="4"
            sm="12"
            v-if="isAdminGudang && pb.diterima == null && pb.diberikan == 1"
          >
            <!-- <p>{{ pb.diterima }}</p> -->
            <b-button variant="info" size="sm" @click="confirmditerima()"
              >Terima dari finance</b-button
            >
          </b-col>
          <b-col md="6" sm="12" class="mb-2">
            <p class="mb-1"><strong>Status</strong></p>
            <!-- Info Status -->
            <b-badge :variant="statusPerbaikan.variant">
              {{ statusPerbaikan.status }}
            </b-badge>
            <!-- / -->
          </b-col>
          <b-col cols="12"></b-col>
          <b-col sm="12" md="6" v-if="isGM && pb.selesai == 1 && pb.gm_approve == 0">
            <b-button size="sm" class="mr-2" v-if="pb.gm_approve == 0" @click.prevent="approveByGM(1)" variant="info">Setujui Ajuan</b-button>
            <b-button size="sm" v-if="pb.gm_approve == 0" @click.prevent="approveByGM(2)" variant="danger">Tolak Ajuan</b-button>
          </b-col>
          <b-col sm="12" md="6" v-if="isFinance && pb.selesai == 1 && pb.id_akun == null && pb.tolak == 0 && pb.gm_approve == 1">
            <b-button size="sm" class="mr-1" variant="info" @click="confirmdiberikan()"
              >Pilih Sumber Pengeluaran</b-button
            >
            <b-button size="sm" variant="danger" @click="confirmtolak()"
              >Tolak Ajuan</b-button
            >
          </b-col>
          <b-col v-if="isFinance && pb.id_akun != null">
            <b-button size="sm" class="mr-1" variant="danger" @click="cancelPerbaikan()"
              >Batalkan Pengeluaran</b-button>
          </b-col>
          <b-col v-if="pb.id_akun > 0 && pb.selesai == 1 && pb.gm_approve == 1">
            <b-badge variant="light-primary" size="sm"
              >Sudah diberikan ke Admin Gudang</b-badge
            >
          </b-col>
          <b-col md="4" sm="12" v-if="pb.diterima == 1 && pb.diberikan == 1">
            <b-badge variant="light-success" size="sm"
              >Sudah diterima oleh Admin Gudang</b-badge
            >
          </b-col>
          <b-col md="4" sm="12" v-if="pb.selesai != 1 && isAdminGudang && pb.gm_approve == 0">
            <b-button size="sm" variant="info" @click="confirmselesai()"
              >Ajukan ke GM</b-button
            >
          </b-col>
          <b-modal
            id="modal-select2"
            v-model="showModalapprfinance"
            title="Pilih Akun dan Kas"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Nominal Biaya Perbaikan">
                <h4>
                  <i class="text-success">
                    <strong>Rp {{ formatRupiah(pb.total) }}</strong>
                  </i>
                </h4>
              </b-form-group>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" class="mb-1" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" class="mb-1" />
                <i class="text-danger" v-if="invalidKas">Saldo Kas kurang dari nominal biaya</i>
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <b-button
                :disabled="!isValidAkunForm"
                type="submit"
                @click.prevent="diberikan"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
            </template>
          </b-modal>
        </b-row>
      </b-card>
    </header>
    <main>
      <b-card action-collapse title="Daftar Ajuan Perbaikan Kendaraan">
        
          <b-row>
            <b-col
              class="my-1"
              v-if="pb.diterima == null && isAdminGudang && pb.selesai != 1"
            >
              <b-button v-b-modal.modal-tambah variant="primary" @click="add()">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Rincian Ajuan
              </b-button>
              <b-modal
                v-model="showModal"
                id="modal-"
                ok-title="Tutup"
                ok-variant="secondary"
                ok-only
                centered
                title="Form "
              >
                <validation-observer ref="formperbaikan">
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19" />
                    <h6 class="ml-50 mb-2">Data Rincian Ajuan Perbaikan Kendaraan</h6>
                  </div>
                  <b-form>
                    <b-row>
                      <!-- item -->
                      <b-col cols="12">
                        <b-form-group label="item" label-for="v-item">
                          <b-form-input
                            v-model="form.item"
                            id="v-item"
                            placeholder="Nama Bagian/Sparepart/Service"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- qty -->
                      <b-col cols="12">
                        <b-form-group label="Qty" label-for="v-qty">
                          <b-form-input
                            v-model="form.qty"
                            id="v-qty"
                            type="number"
                            placeholder="Isi qty"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- biaya -->
                      <b-col cols="12">
                        <b-form-group label="biaya" label-for="v-biaya">
                          <b-form-input
                            v-model="form.biaya"
                            id="v-biaya"
                            @keyup="doFormatRupiah"
                            placeholder="Isi biaya"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col cols="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          @click.prevent="submit"
                          variant="primary"
                          class="mr-1"
                        >
                          Simpan
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="reset"
                          variant="outline-secondary"
                        >
                          Reset
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              v-if="(pb.diterima == null || pb.diberikan == null) && pb.selesai == 0"
            >
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>
                <template #cell(biaya)="{ item }">
                  Rp. {{ formatRupiah(item.biaya) }}
                </template>

                <template #cell(jumlah)="{ item }">
                  Rp. {{ formatRupiah(item.qty * item.biaya) }}
                </template>

                <template
                  #cell(actions)="row"
                  v-if="pb.diterima == null && pb.selesai == 0"
                >
                  <!-- v-if="allowUpdate()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- v-if="allowDelete()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>

            <b-col
              cols="12"
              v-if="
                (pb.diberikan == null && pb.diterima == null && pb.selesai == 1) ||
                (pb.diberikan == 1 && pb.diterima == null && pb.selesai == 1)
              "
            >
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fieldsselesai"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(biaya)="{ item }">
                  Rp. {{ formatRupiah(item.biaya) }}
                </template>

                <template #cell(jumlah)="{ item }">
                  Rp. {{ formatRupiah(item.qty * item.biaya) }}
                </template>

                <template
                  #cell(actions)="row"
                  v-if="pb.diterima == null && pb.selesai == 0"
                >
                  <!-- v-if="allowUpdate()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- v-if="allowDelete()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              v-if="pb.diterima == 1 && pb.diberikan == 1 && pb.selesai == 1"
            >
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fieldsDetail"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(biaya)="{ item }">
                  Rp. {{ formatRupiah(item.biaya) }}
                </template>

                <template #cell(jumlah)="{ item }">
                  Rp. {{ formatRupiah(item.qty * item.biaya) }}
                </template>
                <template
                  #cell(actions)="row"
                  v-if="pb.diterima == null && pb.selesai == 0"
                >
                  <!-- v-if="allowUpdate()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- v-if="allowDelete()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        
      </b-card>
    </main>
  </section>
</b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BCard,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    BCard,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
  },
  data() {
    return {
      loading: false,
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_perbaikan_kendaraan: null,
        item: "",
        qty: 0,
        biaya: 0,
      },
      form2: {
        id_kas: null,
        id_akun: null,
      },
      form3: {},
      form4: {
        selesai: 1,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "tanggal",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      id_kas: [],
      id_akun:[],
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id_perbaikan_kendaraan", label: "Kode", sortable: true },
        { key: "item", label: "item", sortable: true },
        { key: "qty", label: "qty" },
        { key: "biaya", label: "biaya" },
        { key: "jumlah", label: "jumlah" },
        { key: "actions", label: "Aksi" },
      ],
      fieldsselesai: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id_perbaikan_kendaraan", label: "Kode", sortable: true },
        { key: "item", label: "item", sortable: true },
        { key: "qty", label: "qty" },
        { key: "biaya", label: "biaya" },
        { key: "jumlah", label: "jumlah" },
      ],
      fieldsDetail: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id_perbaikan_kendaraan", label: "Kode", sortable: true },
        { key: "item", label: "item", sortable: true },
        { key: "qty", label: "qty" },
        { key: "biaya", label: "biaya" },
        { key: "jumlah", label: "jumlah" },
      ],
      items: [],
      pb: {
        tanggal: null,
        keterangan: null,
        total: null,
        diberikan: null,
        diterima: null,
        selesai: null,
        id_kas: null,
      },
      showModalapprfinance: false,
      formtolak: {
        tolak : 1,
        // diberikan : 0,
      },
    };
  },
  computed: {
    statusPerbaikan() {
      let result = {
        variant: 'light-primary',
        status: 'Belum Selesai'
      }

      if(!this.pb) {
        return result
      }

      if(this.pb.selesai == 1 && this.pb.gm_approve == 0 ) {
        result.variant = 'light-danger'
        result.status = 'Menunggu persetujuan GM'
      }
      else if(this.pb.selesai == 1 && this.pb.gm_approve == 1 && this.pb.id_akun == null && this.pb.tolak == 0) {
        result.variant = 'light-success'
        result.status = 'Disetujui GM'
      }
      else if(this.pb.selesai == 1 && this.pb.gm_approve == 2 && this.pb.id_akun == null && this.pb.tolak == 1) {
        result.variant = 'dark'
        result.status = 'Ditolak GM'
      }
      else if(this.pb.selesai == 1 && this.pb.tolak == 0 && this.pb.id_akun == null) {
        result.variant = 'light-danger'
        result.status = 'Pending'
      }
      else if(this.pb.selesai == 1 && this.pb.tolak == 1) {
        result.variant = 'dark'
        result.status = 'Ditolak Finance'
      }
      else if(this.pb.selesai == 1 && this.pb.id_akun > 0) {
        result.variant = 'success'
        result.status = 'Selesai'
      }

      return result
    },
    invalidAkun() {
      const akun = this.id_akun.find(item => item.id == this.form2.id_akun)
      if(!akun) return false

      return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < this.pb.total)
    },
    invalidKas() {
      const kas = this.id_kas.find(item => item.id == this.form2.id_kas)
      if(!kas) return false

      return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < this.pb.total)
    },
    isValidAkunForm() {
      if(!this.form2.id_akun || !this.form2.id_kas) {
        return false
      }

      // get selected akun and kas and validate saldo
      if(this.invalidKas) {
        return false
      }

      return true

    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsDetail
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getData();
    this.getPb();
    // this.getStatus();
  },
  methods: {
    cancelPerbaikan() {
      this.$swal({
        title: "Anda yakin?",
        text: 'Dana yang sudah dikeluarkan akan dibatalkan',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async res => {
        if(res.value) {
          const payload = {
            id: this.$route.params.id,
            cancel: 1
          }

          this.loading = true
          try {
            await this.$store.dispatch('perbaikan/berikan', [payload])
            this.loading = false
            this.getData();
            this.getPb();
            this.resetForm();
            this.displaySuccess({
              message: 'Pengeluaran dana berhasil dibatalkan'
            })
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
        }
      })
    },
    approveByGM(aksi) {
      this.$swal({
        title: "Anda yakin?",
        text: aksi == 2 ? "Ajuan ini ditolak" : 'Ajuan ini akan anda setujui',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async res => {
        if(res.value) {
          const payload = {
            id: this.$route.params.id,
            aksi
          }

          try {
            this.loading = true
            await this.$store.dispatch('perbaikan/approveGM', [payload])
            this.loading = false
            this.getData();
            this.getPb();
            this.resetForm();
            this.displaySuccess({
              message: `Ajuan perbaikan telah ${aksi == 1 ? 'disetujui' : 'ditolak'}`
            })
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
        }
      })
    },
    doFormatRupiah() {
      this.form.biaya = this.formatRupiah(this.form.biaya)
    },
    confirmtolak() {
      this.$swal({
        title: "Anda yakin?",
        text: "Ajuan ini ditolak",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.ditolak();
        // item.fungsi = 1; // soft delete
        }
      });
      // this.getKas();
      // this.getAkun();
    },
    async ditolak() {
      if (this.$route.params.id) {
        this.formtolak.id = this.$route.params.id;
      }
      const payload = this.formtolak;
      try {
        const ajuan = await this.$store.dispatch("perbaikan/berikan", 
          [payload],
        );
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getData();
          this.getPb();
          this.resetForm();
          this.displaySuccess({
            message: "Ajuan telah ditolak",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    confirmselesai() {
      this.$swal({
        title: "Anda yakin?",
        text: "Mengajukan Rincian Biaya Pengambilang Barang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
        this.selesai();
        }
      });
    },
    confirmditerima() {
      this.$swal({
        title: "Anda yakin?",
        text: "Sudah terima Biaya Pengambilang Barang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
        this.diterima();
        }
      });
    },
    confirmdiberikan() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = item.saldo < 0 ? `${this.formatRupiah(item.saldo)}` : this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_akun = akun.filter(item => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = item.saldo < 0 ? `${this.formatRupiah(item.saldo)}` : this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async diterima() {
      if (this.$route.params.id) {
        this.form3.id = this.$route.params.id;
      }
      const payload = this.form3;
      try {
        const ajuan = await this.$store.dispatch("perbaikan/terima", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Biaya pengambilan barang telah diterima",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async diberikan() {
      if(!this.isValidAkunForm) {
        this.displayError({
          message: 'Harap lengkapi form pemilihan akun dengan benar!'
        })
        return false
      }
      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      try {
        const ajuan = await this.$store.dispatch("perbaikan/berikan", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.resetForm();
          this.displaySuccess({
            message: "Biaya pengambilan barang telah diberikan",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async selesai() {
      if (this.$route.params.id) {
        this.form4.id = this.$route.params.id;
      }
      const payload = this.form4;
      try {
        const ajuan = await this.$store.dispatch("perbaikan/save", [payload]);
        if (this.id) {
          this.getData();
          this.getBarangPb()
        } else {
          this.getPb();
          this.getBarangPb()
          this.displaySuccess({
            message: "Biaya pengambilan barang sudah diajukan ke finance",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async getPb() {
      const pb = await this.$store.dispatch(
        "perbaikan/getDataById",
        this.$route.params.id
      );
      this.pb = pb;
    },
    async getBarangPb() {
      const barangs = await this.$store.dispatch("perbaikan/getData", {
        penerimaan_id: this.$route.params.id,
      });
      this.barangs = barangs;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.showModalapprfinance = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data rincian ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
        item.fungsi = 1; // soft delete
        this.$store
          .dispatch("perbaikan/save2", [item])
          .then(() => {
            this.getData();
            this.displaySuccess({
              message: "Ajuan berhasil dihapus",
            });
            this.resetForm();
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }});
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      this.showModalapprfinance = false;
    },
    submit() {
      this.$refs.formperbaikan.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_perbaikan_kendaraan = this.$route.params.id;
          this.form.biaya = this.unFormatRupiah(this.form.biaya)
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("perbaikan/save2", [payload]);
            this.label = "Submit";
            this.getData();

            this.displaySuccess({
              message: "Rincian biaya pengambilan barang telah disimpan",
            });
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      // let params = this.isAdminGudang ? { id_perbaikan_kendaraan: this.myGudang.id } : {};

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("perbaikan/getData2", {
        id_perbaikan_kendaraan: this.$route.params.id,
      });
      this.loading = false;
      this.items = ajuans;
      // this.items.biaya = formatRupiah(ajuans.biaya);
      this.totalRows = ajuans.length;
    },
    // getid_gudang() {
    //   this.$store
    //     .dispatch("karyawan/getData", { jabatan_id: 5 })
    //     .then(() => {
    //       let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
    //       karyawan.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama_lengkap;
    //       });

    //       this.id_gudang = karyawan;
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
    // getsalesName(data) {
    //   return data.sales ? data.sales.nama_lengkap : "-";
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
